import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, getIdToken, signOut } from "firebase/auth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ApiDataProcessor from "./utils/ApiDataProcessor";
import { convertTimestampToDate, getLiveData } from "./utils/utils";
import axios from "axios";

import ThemedSuspense from './components/ThemedSuspense';

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const Pacman = lazy(() => import("./components/Pacman"));


function App() {
  const [session, setSession] = useState(null);
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [userVerified, setUserVerified] = useState(false)
  const [loggedIn, setLoggedIn] = useState(true);

  const [progress, setProgress] = useState(0);
  const [numAccounts, setNumAccounts] = useState(0);

  const [snapshotsLoaded, setSnapshotsLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [snapshots, setSnapshots] = useState([]);

  const [usingSnapshot, setUsingSnapshot] = useState(false);
  const [snapshotTimestring, setSnapshotTimestring] = useState("");

  const [snapshotData, setSnapshotData] = useState(null);
  const [snapshotDp, setSnapshotDp] = useState(null);

  const [nonLiveSnapDps, setNonLiveSnapDps] = useState(null);
  const [nonLiveSnapTimestrings, setNonLiveSnapTimestrings] = useState("");

  const [data, setData] = useState(null);
  const [dp, setDp] = useState(null);

  const [searchedValue, setSearchedValue] = useState("");

  const [userRole, setUserRole] = useState(false);
  const [userStatus, setUserStatus] = useState(true);

  const [fromLoginPage, setFromLoginPage] = useState(false);
  const [chartDropdownFilterValue, setChartDropdownFilterValue] = useState('all_time');


  const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
    authDomain: process.env.REACT_APP_GOOGLE_AUTH_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_GOOGLE_AUTH_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GOOGLE_AUTH_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_GOOGLE_AUTH_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_GOOGLE_AUTH_APP_ID
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();

  async function signInWithGoogle() {
    signInWithPopup(auth, provider).then((result) => {
      if (!result) {
        console.log('not logged in')
        setSession(null);
      }
    }).catch((error) => {
      if (error.message === 'Firebase: Error (auth/admin-restricted-operation).') {
        showErrorDialog('User unauthorized. Please contact admin for access.')
      } else {
        showErrorDialog('Error logging in.')
      }
      setSession(null);
    });

  }

  function logOut() {
    signOut(auth).then(() => {
      setSession(null)
      setLoggedIn(false)
    }).catch((error) => {
      console.log('signout error')
    });
  }

  const showErrorDialog = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const chartDropdownFilter = (value) => {
    // all_time
    // monthly
    // weekly
    // daily
    setChartDropdownFilterValue(value);
  }

  const getUserRole = (access_token, id) => {
    axios(`${process.env.REACT_APP_BACKEND_API}/auth/get_user_role`,
      {
        headers: {
          "Access-Token": access_token,
          "User-Id": id
        }
      }
    )
      .then((response) => {
        if (response.data.data) {
          let { role, status } = response.data.data;
          setUserRole(role);
          setUserStatus(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyUser = (access_token, id) => {
    axios(`${process.env.REACT_APP_BACKEND_API}/auth/verify_new_user`,
      {
        headers: {
          "Access-Token": access_token,
          "User-Id": id
        }
      }
    )
      .then((response) => {
        setUserVerified(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSnapshots = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/dashboard/snapshots/${chartDropdownFilterValue}`, prepareAccessTokenHeader())
      .then((response) => {
        let snapshot = response.data.data;
        if (snapshot) {
          const snaps = snapshot;
          setSnapshots(snaps);

          const tempNonLiveSnapDps = [];
          const tempNonLiveSnapTimestrings = [];
          for (let i = 0; i < Object.keys(snaps).length; i++) {
            console.log("snaps keys ===>", Object.keys(snaps)[i], "snaps==>", snaps, "keyed snap ===>", snaps[Object.keys(snaps)[i]])
            tempNonLiveSnapDps.push(new ApiDataProcessor(snaps[Object.keys(snaps)[i]].snapshot));
            tempNonLiveSnapTimestrings.push(
              convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[0] +
              "-" +
              convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[1]
            );
          }

          setNonLiveSnapDps(tempNonLiveSnapDps);
          setNonLiveSnapTimestrings(tempNonLiveSnapTimestrings);
        } else {
          setSnapshots([]);
        }
        setSnapshotsLoaded(true);
      }).catch(error => {
        console.log(error)
      })

    /*  const snapshotRef = ref(db, "snapshots/");
     get(snapshotRef)
       .then((snapshot) => {
         if (snapshot.exists()) {
           const snaps = snapshot.val();
           setSnapshots(snaps);

           const tempNonLiveSnapDps = [];
           const tempNonLiveSnapTimestrings = [];
           for (let i = 0; i < Object.keys(snaps).length; i++) {
             tempNonLiveSnapDps.push(new ApiDataProcessor(snaps[Object.keys(snaps)[i]].snapshot));
             tempNonLiveSnapTimestrings.push(
               convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[0] +
                 "-" +
                 convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[1]
             );
           }

           setNonLiveSnapDps(tempNonLiveSnapDps);
           setNonLiveSnapTimestrings(tempNonLiveSnapTimestrings);
         } else {
           setSnapshots([]);
         }
         setSnapshotsLoaded(true);
       })
       .catch((error) => {
         console.log(error);
         setSnapshotsLoaded(false);
         getSnapshots();
       }); */
  };

  const updateProgress = (responses) => {
    setProgress(responses);
    return;
  };

  const getData = async () => {
    /* const d = await getLiveData(updateProgress, setNumAccounts)
    setData(d) */
    try {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API}/dashboard/latest_snapshot`, prepareAccessTokenHeader())
        .then((response) => {
          let snapshot = response.data.data;
          if (snapshot[0]) {
            setData(snapshot[0].snapshot)
          } else {
            setData([]);
          }
        })
      /* .get("${process.env.REACT_APP_BACKEND_API}/test")
        .then((response) => {
        setData([response.data]);
      }) */
    } catch (e) {
      console.log("===== Error on getData =======", e);
    } finally {
      setDataLoaded(true);
    }

  };

  // const writeSnapshot = (snapshot, note) => {
  //   if (!note) {
  //     alert("Please add a note");
  //     return;
  //   }
  //   if (!snapshot) return;

  //   console.log("writing snapshot");
  //   setSnapshotsLoaded(false);
  //   push(ref(db, "snapshots/"), {
  //     snapshot,
  //     note,
  //     timestamp: serverTimestamp()
  //   }).then(() => {
  //     getSnapshots();
  //   });
  // };

  const setDataToSnapshot = (snapshot, timestring) => {
    setSnapshotTimestring(timestring);
    setUsingSnapshot(true);
    setSnapshotData(snapshot);
    setSnapshotDp(new ApiDataProcessor(snapshotData));
  };

  const goLive = () => {
    setUsingSnapshot(false);
  };

  // const delSnapshot = id => {
  //   console.log('deleting snap')
  //   const snapshotRef = ref(db, 'snapshots/')

  //   //deletion
  //   set(ref(db, 'snapshots/' + id), null);

  //   get(snapshotRef).then((snapshot) => {
  //     if (snapshot.exists()) {
  //       const value = snapshot.val()
  //       try {
  //         setSnapshots(value)
  //       } catch(e) {
  //         console.log(e)
  //       }
  //     } else {
  //       setSnapshots([])
  //     }
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // }
  useEffect(() => {
    onAuthStateChanged(auth, (auth_user) => {
      if (auth_user) {
        getIdToken(auth_user).then((token) => {
          let auth_session = {};

          auth_session.access_token = token;
          auth_session.user = auth_user;

          setSession(auth_session);
          console.log('logged in')
          setLoggedIn(true)
        })
      } else {
        console.log('not logged in')
        setSession(null);
        setLoggedIn(false)
      }
      setSessionLoaded(true);
    })
  }, []);

  useEffect(() => {
    getData();
    getSnapshots();
    setDataLoaded(false);
    if (session) {
      verifyUser(session.access_token, session.user.uid)
    }
  }, [session]);

  useEffect(() => {
    if (session && userVerified) {
      getUserRole(session.access_token, session.user.uid);
    }
  }, [userVerified]);

  useEffect(() => {
    getData();
    getSnapshots();
  }, [chartDropdownFilterValue]);

  useEffect(() => {
    setDp(new ApiDataProcessor(data));
    setDataLoaded(true);
  }, [data]);

  useEffect(() => {
    setSnapshotDp(new ApiDataProcessor(snapshotData));
  }, [snapshotData]);

  useEffect(() => {
    if (!userStatus) {
      logOut()
    }
  }, [userStatus]);

  const prepareAccessTokenHeader = () => {
    if (session) {
      return {
        headers: {
          "Access-Token": session.access_token,
          "User-Id": session.user.uid
        }
      };
    }

    return {
      headers: null
    };
  }

  // console.log('s' + snapshotsLoaded)
  // console.log('a' + accountsLoaded)
  // console.log('d' + dataLoaded)

  if (sessionLoaded) {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/login" component={() => <Login session={session} role={userRole} status={userStatus} signInWithGoogle={signInWithGoogle} showErrorDialog={showErrorDialog} />} />
            {!session && !loggedIn && < Redirect from="/" to="/login" />}
            <Route
              path="/"
              render={() => (
                <Layout
                  snapshots={snapshots}
                  data={usingSnapshot ? snapshotData : data}
                  dp={usingSnapshot ? snapshotDp : dp}
                  // writeSnapshot={writeSnapshot}
                  setDataToSnapshot={setDataToSnapshot}
                  usingSnapshot={usingSnapshot}
                  snapshotTimestring={snapshotTimestring}
                  nonLiveSnapDps={nonLiveSnapDps}
                  nonLiveSnapTimestrings={nonLiveSnapTimestrings}
                  goLive={goLive}
                  searchedValue={searchedValue}
                  setSearchedValue={setSearchedValue}
                  progress={progress}
                  numAccounts={numAccounts}
                  chartDropdownFilter={chartDropdownFilter}
                  chartDropdownFilterValue={chartDropdownFilterValue}
                  role={userRole}
                  session={session}
                  everythingLoaded={snapshotsLoaded && dataLoaded && userRole}
                  signOut={logOut}
                />
              )}
            />
          </Switch>
        </Router>
      </>
    );
  } else {
    return (
      <ThemedSuspense
        classes={"h-screen"}
      />
    );
  }
}

export default App;
